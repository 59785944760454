import { storeToRefs } from "pinia";
import { useUserStore } from "~/store/UserStore";
import { leaveImpersonationMode } from "~/api/officehub";

function useOfficehub() {
  const isOfficehubImpersonation = computed(() => {
    const { user } = storeToRefs(useUserStore());
    return process.client && !!user?.value?.is_impersonating;
  });

  const isOfficehubEnabled = computed(() => {
    const { user } = storeToRefs(useUserStore());
    return user?.value?.customer?.officehub_enabled || false;
  });

  const managedByCustomerName = computed(() => {
    const { user } = storeToRefs(useUserStore());
    return user?.value?.customer?.managed_by?.name || null;
  });

  const impersonatedCustomerName = computed(() => {
    const { user } = storeToRefs(useUserStore());
    return user?.value?.customer?.name || null;
  });

  async function leaveOfficehubImpersonationMode() {
    const { user } = storeToRefs(useUserStore());
    const { data, error } = await leaveImpersonationMode();

    if (error.value) {
      useBugsnag().notify(
        new Error(
          `Token exchange (impersonation token => customer token) failed for impersonating customer.`,
        ),
        (event) => {
          event.addMetadata("additional", {
            error,
            user,
          });
        },
      );
      throw error.value;
    }
    return data.value.token;
  }

  return {
    isOfficehubImpersonation,
    isOfficehubEnabled,
    managedByCustomerName,
    impersonatedCustomerName,
    leaveOfficehubImpersonationMode,
  };
}

export { useOfficehub };
